import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {JsonPipe, NgForOf, NgIf} from '@angular/common';
import {AbstractControl, FormControl, ValidationErrors} from '@angular/forms';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-validation-error',
  standalone: true,
  imports: [NgIf, NgForOf, JsonPipe, TranslateModule],
  templateUrl: './validation-error.component.html',
  styleUrl: './validation-error.component.scss',
})
export class ValidationErrorComponent {
  @Input() control?: any;

  constructor() {

  }

  trackByItems(index: number, item: ValidationErrors): number {
    return index; // or index
  }


  protected readonly Object = Object;
}
