<!--@if (control?.invalid  && (control?.dirty || control?.touched) && control?.errors !== undefined && control?.errors !== null){-->
<!--  <div  class="error-messages">-->
<!--    @if (control.errors['required']){-->
<!--      <p >This field is required.</p>-->
<!--    }-->
<!--    @if (control.errors['minlength']){-->
<!--      <p>Minimum length should be.</p>-->
<!--    }-->
<!--  </div>-->
<!--}-->
<p class="h-2">
@if(control && control?.invalid  && (control?.dirty || control?.touched) && control?.errors){
<!--  @for (error of Object.keys(control?.errors);let i = $index; track i) {-->
    {{Object.keys(control?.errors)[0] | translate}}
<!--  }-->
  <!-- Now, we can safely check for specific errors since we know errors exist -->

<!--  <p *ngIf="errors[email]" ></p>-->
  <!-- Add other error messages as needed -->
}
</p>
