import { Directive, ElementRef, Input, Renderer2, HostListener } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appTooltip]',
})
export class TooltipDirective {
  @Input('appTooltip') toolTipText: string = '';
  private toolTip: HTMLElement | undefined;
  private relativeContainer: HTMLElement | undefined; // Adding relative container
  private infoIcon: HTMLElement | undefined; // Adding info icon

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter') onMouseEnter() {
    if (this.infoIcon) { this.show(); }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.infoIcon) { this.hide(); }
  }

  private create() {
    this.relativeContainer = this.renderer.createElement('div'); // Creating the relative container
    this.renderer.setStyle(this.relativeContainer, 'display', 'inline'); // Setting the display style so it doesn't block other elements
    this.renderer.setStyle(this.relativeContainer, 'position', 'absolute'); // Setting the display style so it doesn't block other elements
    this.renderer.setStyle(this.relativeContainer, 'top', '0'); // Setting the display style so it doesn't block other elements
    this.renderer.setStyle(this.relativeContainer, 'right', '0'); // Settings its position to relative

    // Creating info icon
    this.infoIcon = this.renderer.createElement('span');
    this.renderer.addClass(this.infoIcon, 'info-icon');

    this.toolTip = this.renderer.createElement('span');

    this.renderer.appendChild(
      this.toolTip,
      this.renderer.createText(this.toolTipText)
    );
    this.renderer.appendChild(
      this.infoIcon,
      this.renderer.createText('i')
    );

    this.renderer.appendChild(this.relativeContainer, this.toolTip); // Appending the toolTip to the relative container instead of the nativeElement

    this.renderer.appendChild(this.relativeContainer, this.infoIcon); // Appending the information icon to the relative container
    this.renderer.appendChild(this.el.nativeElement, this.relativeContainer); // Appending the container to the nativeElement

    this.renderer.setStyle(this.toolTip, 'position', 'absolute');
    this.renderer.setStyle(this.toolTip, 'top', '0');
    this.renderer.setStyle(this.toolTip, 'left', '100%');
    this.renderer.setStyle(this.toolTip, 'display', 'none');
    this.renderer.setStyle(this.toolTip, 'background', '#333');
    this.renderer.setStyle(this.toolTip, 'color', '#fff');
    this.renderer.setStyle(this.toolTip, 'padding', '5px');
    this.renderer.setStyle(this.toolTip, 'border-radius', '2px');
    this.renderer.setStyle(this.toolTip, 'font-size', '10px');
    this.renderer.setStyle(this.toolTip, 'box-shadow', '0 0 3px #999');
  }

  private show() {
    this.renderer.setStyle(this.toolTip, 'display', 'block');
  }

  private hide() {
    this.renderer.setStyle(this.toolTip, 'display', 'none');
  }

  ngOnInit() {
    this.create();
  }
}
